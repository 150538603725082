import axios from 'axios';
import {
	model, ExtendedModel, prop, idProp, ModelCreationData, modelFlow, _async, _await,
} from 'mobx-keystone';
import { reaction, IReactionDisposer } from 'mobx';

import { type CreateAccountOptions } from '~/engage/toolbar/set-up-online-access/openSetUpOnlineAccessModal';
import { type ViewStateView } from '~/util/viewState/ViewState.interface';
import { type IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { ERROR_KEY } from '~/util/messaging/promise-error/error.constants';
import { setUpOnlineAccessSettings } from '~/engage/toolbar/set-up-online-access/setUpOnlineAccess.settings';
import { TextMessagingOptIn } from '~/engage/toolbar/set-up-online-access/text-messaging-opt-in/TextMessagingOptIn';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { SetUpOnlineAccessView } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.view';
import { isOnServer } from '~/global/global.constants';
import { modelNamespace } from '~/util/modelNamespace';
import { AbstractFormStore } from '~/util/abstract-form-store/AbstractForm.store';
import { isString } from '~/util/isString';
import { rnbFetch } from '~/util/rnbFetch.util';
import { magicModalCtx, globalDynamicCtx } from '~/global/Models/Global.model';
import { SuccessView as OnlineAccessSuccessView } from '~/engage/toolbar/set-up-online-access/Success.view';
import { SuccessView as TextMessagingSuccessView } from '~/engage/toolbar/set-up-online-access/text-messaging-opt-in/Success.view';
import { noop } from '~/util/noop';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';

export type FormModel = {
	username: string
	zipCode: string
	optIn: boolean
};

type CreateAccountDto = {
	sapCustomerFullName?: string
	_links?: {
		createAccount?: {
			href: string
		}
	}
};

export const isCreateAccountDtoType = (
	subject: unknown,
): subject is CreateAccountDto => {
	if (!subject || typeof subject !== 'object') {
		return false;
	}
	const hasSapCustomerFullName = 'sapCustomerFullName' in subject && isString(subject.sapCustomerFullName);
	const hasCreateAccountHref = (
		'_links' in subject
		&& typeof subject._links === 'object'
		&& subject._links !== null
		&& 'createAccount' in subject._links
		&& subject._links.createAccount !== null
		&& typeof subject._links.createAccount === 'object'
		&& 'href' in subject._links.createAccount
		&& isString(subject._links.createAccount.href)
	);

	return hasSapCustomerFullName || hasCreateAccountHref;
};

const views = {
	MAIN: 'MAIN',
	SUCCESS: 'SUCCESS',
	TEXT_MESSAGING_OPT_IN_SUCCESS: 'TEXT_MESSAGING_OPT_IN_SUCCESS',
} as const;

type View = typeof views;
export type ViewKey = keyof View;

type ViewStateConfig = {
	[key in ViewKey]: ViewStateView
};

@model(`${modelNamespace.ENGAGE_TOOLBAR}/CreateAccountStore`)
export class SetUpOnlineAccessStore extends ExtendedModel(AbstractFormStore<FormModel>, {
	id: idProp,
	username: prop(''),
	zipCode: prop(''),
	optIn: prop(false),
	sapCustomerFullName: prop('').withSetter(),
	onCreateAccountSuccessView: prop<ViewKey | null>('SUCCESS'),
	marketingTextMessagingLink: prop<string | undefined>().withSetter(),
}) {
	static create(
		initialModelData: ModelCreationData<SetUpOnlineAccessStore> = {},
		options: Partial<CreateAccountOptions> = {},
	) {
		const store = new _SetUpOnlineAccessStore(initialModelData);
		const {
			onCreateAccountSuccess = noop,
		} = options;

		store.setOnCreateAccountSuccess(onCreateAccountSuccess);
		if (store.magicOverlayStore) {
			store.magicOverlayStore.model.position = 'GLOBAL';
		}
		store.viewState = new ViewStateStore(store, store.viewStateConfig);
		globalDynamicCtx.getDefault().registerClientSideInitialLoadCallback(async () => {
			await store.fetchSapCustomerFullName();
			store.viewState.goTo('MAIN');
		});
		// @ts-ignore
		// global.store = store;
		return store;
	}

	get hyphenatedSapCustomerFullName() {
		return this.sapCustomerFullName.replace(/(.{5})/g, '$1\u00AD');
	}

	get formModel(): FormModel {
		return {
			username: this.username,
			zipCode: this.zipCode,
			optIn: this.optIn,
		};
	}

	get formSettings(): IFormSettings {
		return setUpOnlineAccessSettings(
			this.submitHandler.bind(this),
			this.promiseHandler.bind(this),
			this.reactionSettings,
		);
	}

	@modelFlow
	fetchSapCustomerFullName = _async(function* (this: SetUpOnlineAccessStore) {
		const gdModel = globalDynamicCtx.getDefault().model as Record<string, any>;
		const link = gdModel?.createAccountLink;

		try {
			if (!link || !isString(link)) {
				throw new Error('No createAccountLink found.');
			}
			const promise = yield* _await(rnbFetch(link));
			const json = yield* _await(promise.json());

			if (!isCreateAccountDtoType(json)) {
				throw new Error(`Expected json to be of type CreateAccountDto but received ${typeof json}`);
			}
			const { sapCustomerFullName = '' } = json;

			this.setSapCustomerFullName(sapCustomerFullName);
		} catch (error: unknown) {
			console.error('Error getting sapCustomerFullName:', error);
		}
	});

	@modelFlow
	formSubmitResolve = _async(function* (this: SetUpOnlineAccessStore, response: Response) {
		try {
			yield* _await(globalDynamicCtx.getDefault().fetchData());
			yield* _await(this.getMarketingTextMessagingLink(response));
			this.onCreateAccountSuccess();
			if (this.onCreateAccountSuccessView) {
				this.viewState.goTo(this.onCreateAccountSuccessView);
			}
		} catch (error: unknown) {
			this.setFetchError(error);
		}
	});

	@modelFlow
	getMarketingTextMessagingLink = _async(function* (this: SetUpOnlineAccessStore, response: Response) {
		try {
			const data = yield* _await(response.json());

			if (
				!data
				|| typeof data !== 'object'
				|| !('_links' in data)
				|| !data._links
				|| typeof data._links !== 'object'
				|| !('marketingTextMessaging' in data._links)
				|| !data._links.marketingTextMessaging
				|| typeof data._links.marketingTextMessaging !== 'object'
				|| !('href' in data._links.marketingTextMessaging)
				|| !isString(data._links.marketingTextMessaging.href)
			) {
				return null;
			}
			this.setMarketingTextMessagingLink(data._links.marketingTextMessaging.href);
			return this.marketingTextMessagingLink;
		} catch (error: unknown) {
			console.error(error);
			return null;
		}
	});

	onCreateAccountSuccess: () => void = noop;

	get payload() {
		return JSON.stringify(this.form.model);
	}

	reactionDisposers: IReactionDisposer[] = [];

	reactionSettings = (form: any) => {
		if (isOnServer) {
			return;
		}
		const fn = () => this.setFetchError();

		this.reactionDisposers.push(
			reaction(() => form.model.username, fn),
			reaction(() => form.model.zipCode, fn),
		);
	};

	get saveUrl(): RequestInfo | URL {
		return globalDynamicCtx.getDefault().model.createAccountLink;
	}

	setOnCreateAccountSuccess(fn: () => void = noop) {
		this.onCreateAccountSuccess = fn;
	}

	get showAccountExistsError() {
		if (!this.fetchError) {
			return false;
		}
		return this.fetchError.errorKey === ERROR_KEY.ACCOUNT_EXISTS_SET_UP_ONLINE_ACCESS_ERROR;
	}

	get showForbiddenTransZoneError() {
		if (!this.fetchError) {
			return false;
		}
		return this.fetchError.errorKey === ERROR_KEY.TRANSPORTATION_ZONE_FORBIDDEN;
	}

	get showServerError() {
		if (this.showAccountExistsError || this.showForbiddenTransZoneError) {
			return false;
		}
		return Boolean(this.fetchError);
	}

	get submitButtonDisabled() {
		if (!this.form) {
			return false;
		}
		return (
			this.form.plugins.formValidator.hasErrors
			&& !this.showAccountExistsError
			&& !this.showForbiddenTransZoneError
		);
	}

	async submitHandler(form: FormBuilder): Promise<Response> {
		this.setFetchError();
		form.plugins.formValidator.validateForm();

		if (form.plugins.formValidator.hasErrors) {
			return Promise.reject('Form invalid.');
		}

		if (this.saveUrl === '') {
			return Promise.reject('No save url found');
		}

		if (this.saveUrl === 'cancelled') {
			return Promise.resolve(new Response());
		}

		this.setIsLoading(true);
		this.magicOverlayStore?.startLoading?.();

		try {
			await this.updateDeliveryZipCode(form.model.zipCode);
		} catch (error: unknown) {
			if (axios.isAxiosError(error) && error.response?.status === 404) {
				return Promise.reject({
					errorKey: 'transportationZoneForbidden',
				});
			}
			return Promise.reject(error);
		}
		return rnbFetch(this.saveUrl, {
			requestHeaders: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json',
			},
			options: {
				method: this.saveMethod,
				body: this.payload,
			},
		});
	}

	@modelFlow
	updateDeliveryZipCode = _async(function* (this: SetUpOnlineAccessStore, zipCode: string) {
		yield* _await(globalDynamicCtx.getDefault().transZoneStore.setTransZone(zipCode));
	});

	viewStateConfig: ViewStateConfig = {
		MAIN: {
			component: SetUpOnlineAccessView,
			onEnter: (_, store: SetUpOnlineAccessStore) => {
				const title = this.hyphenatedSapCustomerFullName
					? `Set Up Online Access for ${this.hyphenatedSapCustomerFullName}`
					: 'Set Up Online Access';

				magicModalCtx.getDefault().alterModal({ title });
				return { store };
			},
		},
		SUCCESS: {
			component: OnlineAccessSuccessView,
			onEnter: (_, store: SetUpOnlineAccessStore) => {
				magicModalCtx.getDefault().alterModal({
					title: 'Success! Access Granted',
				});
				if (!store.marketingTextMessagingLink) {
					console.warn('No marketingTextMessagingLink found. Text messaging opt in form will not be shown.');
					return {};
				}
				const textMessagingOptIn = new TextMessagingOptIn({
					marketingTextMessagingLink: store.marketingTextMessagingLink,
				});

				textMessagingOptIn.setFormSubmitResolve(() => {
					store.viewState.goTo('TEXT_MESSAGING_OPT_IN_SUCCESS');
				});
				return { store: textMessagingOptIn };
			},
		},
		TEXT_MESSAGING_OPT_IN_SUCCESS: {
			component: TextMessagingSuccessView,
			onEnter: () => {
				magicModalCtx.getDefault().alterModal({
					title: 'Successful Opt-in',
				});
			},
		},
	};

	viewState!: ViewStateStore;
}

const _SetUpOnlineAccessStore = SetUpOnlineAccessStore;
