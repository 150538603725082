import { action } from 'mobx';
import { type ModelCreationData } from 'mobx-keystone';

import { type MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { SetUpOnlineAccess } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess';
import { SetUpOnlineAccessStore, type ViewKey } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.store';
import { noop } from '~/util/noop';

export type CreateAccountOptions = {
	formDefaults: Partial<ModelCreationData<SetUpOnlineAccessStore>>
	modalOptions: Record<string, any>
	onCreateAccountSuccess: () => void
	onCreateAccountSuccessView: ViewKey
};

export const openSetUpOnlineAccessModal = action((
	magicModal: MagicModalStore,
	globalDynamicStore: GlobalDynamicStore,
	options: Partial<CreateAccountOptions> = {},
) => {
	const { modalOptions } = options;

	const initialModelData = {
		...options.formDefaults,
	};
	const {
		formDefaults,
		...config
	} = options;
	const onCreateAccountSuccessView: ViewKey = 'SUCCESS';
	const storeOptions = {
		formDefaults: initialModelData,
		modalOptions: {},
		onCreateAccountSuccess: noop,
		onCreateAccountSuccessView,
		...config
	};
	const store = SetUpOnlineAccessStore.create(initialModelData, storeOptions);

	magicModal.openModal({
		id: 'create-account',
		title: 'Set Up Online Access',
		maxWidth: '535px',
		content: {
			children: <SetUpOnlineAccess store={store} />,
		},
		...modalOptions,
	});
});
