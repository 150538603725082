import { apiUrl } from '~/global/global.constants';

export type Options = Partial<{
	requestHeaders: HeadersInit
	options: Omit<RequestInit, 'headers'>
}>;

export const rnbFetch = async (
	url: RequestInfo | URL,
	params: Options = {
		options: { method: 'get', credentials: 'include' },
	},
): Promise<Response> => {
	const apiRequestUrl = `${apiUrl}/${url}`.replace('//api', '/api');
	const ts = performance.now();
	const {
		requestHeaders: headers,
		options,
	} = params;
	const response = await fetch(apiRequestUrl, {
		method: 'get',
		credentials: 'include',
		headers,
		...options,
	});
	const measure = performance.now() - ts;

	console.log(`rnbFetch ${apiRequestUrl}: ${Math.round(measure)}ms`);

	return response;
};
