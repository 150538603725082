import { type TextMessagingOptIn } from '~/engage/toolbar/set-up-online-access/text-messaging-opt-in/TextMessagingOptIn';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { TextMessagingOptInForm } from '~/engage/toolbar/set-up-online-access/text-messaging-opt-in/TextMessagingOptInForm';

export const SuccessView = ({ store }: { store?: TextMessagingOptIn }) => {
	const { magicModal } = useGlobalContext();

	return (
		<div>
			<p>An email has been sent with information on how to complete account setup and see their Cart and
				Favorites.</p>
			{
				store !== undefined
					?
					<TextMessagingOptInForm store={store} />
					:
					<div>
						<button
							className="Button"
							data-qa="create-account-success-modal-close-button"
							onClick={magicModal.closeModal}
						>
							Close
						</button>
					</div>
			}
		</div>
	);
};
